import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DatabaseService } from 'src/app/Service/Database/databaseserv.service';
import { Orar } from 'src/app/model/orar';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Pauza } from 'src/app/model/pauza';


@Component({
  selector: 'app-orar-list',
  templateUrl: './orar-list.component.html',
  styleUrls: ['./orar-list.component.css']
})


export class OrarListComponent implements OnInit {

  // Display Content 
  header_orar_denumire: string = "[Neselectat]";
  header_total_timp: string = "[Neselectat]";
  header_timp_de_lucru: string = "[Neselectat]";
  header_timp_de_pauza: string = "[Neselectat]";
  header_punct_de_lucru: string = "[Neselectat]";
  header_orar_interval: string = "[Neselectat]";


  //Punct de lucru
  ptDataSource: any;
  ptSelection: any;
  pt_load: boolean = false;

  //table Orar
  load_orar: any
  orarDataSource: any;
  orarCols: any;
  orarSelection: any;

  //Tabel pauza
  load_pauza: any
  pauzaDataSource: any;
  pauzaCols: any;
  pauzaSelection: any;


  //Objects
  orar: Orar;
  pauza: Pauza;

  //Dialogs Orar
  dialogOrarActionAdd: boolean = false;
  dialogOrarActionEdit: boolean = false;
  dialogOrarActionDelete: boolean = false;


  //Dialogs Pauza
  dialogPauzaActionAdd: boolean = false;
  dialogPauzaActionEdit: boolean = false;
  dialogPauzaActionDelete: boolean = false;


  //VALIDARE DIALOG !
  statusDialog: string;
  statusDialogMessage: Array<String>;


  constructor(private myMessage: MessageService, private dbs: DatabaseService) {
    this.orarCols = [
      { field: 'denumire', header: 'denumire' },
      { field: 'start', header: 'start' },
      { field: 'stop', header: 'stop' },
      { field: 'night', header: 'night' },
      { field: 'total', header: 'total' },
      { field: 'lucru', header: 'lucru' },
      { field: 'pauza', header: 'pauza' }
    ];

    this.pauzaCols = [
      { field: 'denumire', header: 'denumire' },
      { field: 'start', header: 'start' },
      { field: 'stop', header: 'stop' },
      { field: 'night', header: 'night' },
      { field: 'timp_pauza', header: 'pauza' }
    ];

    this.orar = new Orar(0, "", "", "", 0, 0);
    this.pauza = new Pauza(0, "", "", "", 0, 0);
  }


  ngOnInit() {
    this.getPunctDeLucru();
  }





  orarOnRowUnSelect() {
    this.header_orar_denumire = "[Neselectat]";
    this.header_total_timp = "[Neselectat]";
    this.header_timp_de_lucru = "[Neselectat]";
    this.header_timp_de_pauza = "[Neselectat]";
    this.header_orar_interval = "[Neselectat]";
    this.pauzaDataSource = null;
  }


  orarOnRowSelect() {
    if (!this.orarSelection) { return; }
    this.header_orar_denumire = this.orarSelection.denumire + " [" + this.orarSelection.start + " - " + this.orarSelection.stop + " ]";
    this.header_total_timp = this.orarSelection.total;
    this.header_timp_de_lucru = this.orarSelection.lucru;
    this.header_timp_de_pauza = this.orarSelection.pauza;
    this.header_orar_interval = this.orarSelection.start + ' -> ' + this.orarSelection.stop;
    this.getPauza(this.orarSelection.id);

  }


  // ORAR 
  orarTableButton(val: string) {
    switch (val) {

      case "Refresh": {
        if (!this.ptSelection) { return; }
        this.onptChange();
        break;
      }

      case "Add": {
        if (!this.ptSelection) { return; }
        this.orar = new Orar(0, "", "", "", 0, this.ptSelection.id);
        this.dialogOrarActionAdd = true;
        break;
      }

      case "Edit": {
        if (!this.orarSelection) { return;   }
        this.orar = new Orar(this.orarSelection.id, this.orarSelection.denumire, this.orarSelection.start, this.orarSelection.stop, this.orarSelection.night, this.ptSelection.id);
        this.dialogOrarActionEdit = true;
        break;
      }

      case "Delete": {
        if (!this.orarSelection) { return;    }
        this.orar = new Orar(this.orarSelection.id, this.orarSelection.denumire, this.orarSelection.start, this.orarSelection.stop, this.orarSelection.night, this.ptSelection.id);
        this.dialogOrarActionDelete = true;
        break;
      }
    }

  }

  // PAUZE 
  pauzaTableButton(val: string) {
    switch (val) {

      case "Add": {
        if (!this.ptSelection) { return; }
        if (!this.orarSelection) { return; }
        this.pauza = new Pauza(0, "", "", "", 0, this.orarSelection.id);
        this.dialogPauzaActionAdd = true;
        break;
      }

      case "Edit": {
        if (!this.ptSelection) { return; }
        if (!this.orarSelection) { return; }
        if (!this.pauzaSelection) { return; }
        this.pauza = new Pauza(this.pauzaSelection.id, this.pauzaSelection.denumire, this.pauzaSelection.start, this.pauzaSelection.stop, this.pauzaSelection.night, this.orarSelection.id);
        this.dialogPauzaActionEdit = true;
        break;
      }

      case "Delete": {
        if (!this.ptSelection) { return; }
        if (!this.orarSelection) { return; }
        if (!this.pauzaSelection) { return; }
        this.pauza = new Pauza(this.pauzaSelection.id, this.pauzaSelection.denumire, this.pauzaSelection.start, this.pauzaSelection.stop, this.pauzaSelection.night, this.orarSelection.id);
        this.dialogPauzaActionDelete = true;
        break;
      }
    }

  }



  onptChange() {
    this.orarSelection = null;
    this.pauzaSelection = null;
    this.orarDataSource = null;
    this.orarOnRowUnSelect(); 
    if (this.ptSelection == null) { return; }
    this.getOrar(this.ptSelection.id);
  }




  // ==========================  ORAR BUTTON ACTION ADD/EDIT/DELETE =============================
  onOrarAdaugaClick(orar: Orar) {
    console.log(orar);
    this.dbs.ApiOrarAdd(orar).subscribe(
      data => {
        this.myMessage.add({ severity: 'success', summary: 'Adauga Orar', detail: data.Message, life: 5000, closable: false });
        this.getOrar(this.ptSelection.id);
      },
      error => {
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      }
    );

  }

  onOrarEditClick(orar: Orar) {
    console.log(orar);
    this.dbs.ApiOrarEdit(orar).subscribe(
      data => {
        this.myMessage.add({ severity: 'success', summary: 'Modifica Orar', detail: data.Message, life: 5000, closable: false });
        this.getOrar(this.ptSelection.id);
      },
      error => {
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      }
    );

  }

  onOrarDeleteClick(orar: Orar) {
    console.log(orar);
    this.dbs.ApiOrarDelete(orar).subscribe(
      data => {
        this.myMessage.add({ severity: 'success', summary: 'Sterge Orar', detail: data.Message, life: 5000, closable: false });
        this.getOrar(this.ptSelection.id);
      },
      error => {
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      }
    );

  }


  // ==========================  PAUZA BUTTON ACTION ADD/EDIT/DELETE =============================

  onPauzaAdaugaClick(pauza: Pauza) {
    console.log(pauza);

    this.dbs.ApiPauzaAdd(pauza).subscribe(
      data => {
        this.myMessage.add({ severity: 'success', summary: 'Adauga Orar', detail: data.Message, life: 5000, closable: false });
        this.getPauza(pauza.id_orar);
      },
      error => {
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      }
    );

  }

  onPauzaEditClick(pauza: Pauza) {
    console.log(pauza);

    this.dbs.ApiPauzaEdit(pauza).subscribe(
      data => {
        this.myMessage.add({ severity: 'success', summary: 'Modifica Orar', detail: data.Message, life: 5000, closable: false });
        this.getPauza(pauza.id_orar);
      },
      error => {
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      }
    );

  }

  onPauzaDeleteClick(pauza: Pauza) {
    console.log(pauza);

    this.dbs.ApiPauzaDelete(pauza).subscribe(
      data => {
        this.myMessage.add({ severity: 'success', summary: 'Sterge Orar', detail: data.Message, life: 5000, closable: false });
        this.getPauza(pauza.id_orar);
      },
      error => {
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      }
    );

  }






  //====================================== API =====================================================

  getPunctDeLucru() {
    this.pt_load = true;
    this.dbs.ApiPunctdelucruList()
      .subscribe(data => {
        this.ptDataSource = data;
        this.pt_load = false;
      }, error => {
        this.pt_load = false;
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      });

  }

  getOrar(id_plucru: number) {
    this.load_orar = true;
    this.dbs.ApiOrarList(id_plucru)
      .subscribe(data => {
        this.load_orar = false;
        this.orarDataSource = data;
      }, error => {
        this.load_orar = false;
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      });
  }

  getPauza(id_orar: number) {
    this.load_pauza = true;
    this.pauzaDataSource = null;
    this.dbs.ApiPauzaList(id_orar)
      .subscribe(data => {
        this.load_pauza = false;
        this.pauzaDataSource = data;
      }, error => {
        this.load_pauza = false;
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      });
  }




}
