export class Orar {
    id: number;
    denumire: string;
    start: string;
    stop: string;
    id_plucru:number;
    night: number;
    denumire_start_stop:string;

    //=== Additional Calculated Field !
    total:number;
    lucru:number;
    pauza:number;
    



    constructor(id: number, denumire: string, start: string, stop: string,night:number,id_plucru:number) {
      this.id = id;
      this.denumire = denumire;
      this.start = start;
      this.stop = stop;
      this.id_plucru=id_plucru;
      this.night=night;
    }
  
  }
