import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DatabaseService } from 'src/app/Service/Database/databaseserv.service';
import { ReturnStatement } from '@angular/compiler';
import { Articol } from 'src/app/model/articol';
import { Faza } from 'src/app/model/faza';


@Component({
  selector: 'app-norma-productie-page',
  templateUrl: './norma-productie-page.component.html',
  styleUrls: ['./norma-productie-page.component.css']

})

export class NormaProductiePageComponent implements OnInit {


  //Punct de Lucru
  ptDataSource: any;
  ptSelection: any;
  pt_load: boolean = false;
  pt_need: boolean = true;

  //Angjati
  angajatDataSource: any;
  angajatSelection: any;
  angajatCols: any;
  angajat_load: boolean = false;

  //Orar
  orarDataSource: any;
  orarSelection: any;
  orar_load: boolean = false;


  //Orar
  statusDataSource: any;
  statusSelection: any;
  status_load: boolean = false;


  //Articol
  articolDataSource: any;
  articolCols: any;
  articolSelection: any = null;
  articol_load: boolean = false;

  //Faza
  fazaDataSource: any;
  fazaCols: any;
  fazaSelection: any = null;
  faza_load: boolean = false;

  //pauza
  pauzaDataSource: any;
  pauzaCols: any;
  pauza_load: boolean = false;

  //DIALOG
  dialogAngajatCautare: boolean = false;
  dialogArticolCautare: boolean = false;
  dialogFazaCautare: boolean = false;
  dialogViewPauza: boolean = false;

  //VALIDARE DIALOG !
  statusDialog: string;
  statusDialogMessage: Array<String>;

  // VARIABILE 
  start: string = "00:00";
  stop: string = "00:00";
  night: boolean = false;

  dataIntroducerii: any;



  frozenCols: any;
  scrollableCols: any;
  cars: any;
  cols: any;

  // Productie
  productieCols: any;
  productieDataSource: any;

  //OBJECTS 
  articol: Articol;
  faza: Faza;



  constructor(private myMessage: MessageService, private dbs: DatabaseService) {
    this.articolCols = [
      { field: 'denumire', header: 'Articol Denumire' },
      { field: 'cod', header: 'Cod Articol' }
    ];

    this.fazaCols = [
      { field: 'denumire', header: 'Faza Denumire' },
      { field: 'cod', header: 'Faza Cod' },
      { field: 'timp', header: 'Faza Timp' }
    ];

    this.pauzaCols = [
      { field: 'denumire', header: 'denumire' },
      { field: 'start', header: 'start' },
      { field: 'stop', header: 'stop' },
      { field: 'night', header: 'night' },
      { field: 'timp_pauza', header: 'pauza' }
    ];


    this.productieCols = [
      { field: 'crt', header: 'Crt.', width: '50px' },
      { field: 'status', header: 'Status de Lucru', width: '150px' },
      { field: 'orar', header: 'Orar de Lucru', width: 'auto' },
      { field: 'start', header: 'Start', width: '50px' },
      { field: 'stop', header: 'Stop', width: '50px' },
      { field: 'aDenumire', header: 'Articol Denumire', width: 'auto' },
      { field: 'fDenumire', header: 'Faza Denumire', width: 'auto' },
      { field: 'bRealizate', header: 'Bucati', width: '100px' },
    ];

    this.statusDialogMessage = [];
    this.articol = new Articol(0, "-", "-");
    this.faza = new Faza(0, "-", "-", 0, 0);
  }

  ngOnInit() {
    this.productieDataSource = [
      { crt: '0', status: '[NONE]', orar: 'orar selectie ok1', start: '12:35', stop: '12:00', aDenumire: 'fdfff ffff fffhghhhhh', aCod: 'dfffff', fDenumire: '33333333333333 4444444443333333 333333', fCod: '77777777777777', fTimp: '34.009', bRealizate: '345' },
      { crt: '0', status: '[NONE]', orar: 'orar selectie ok2', start: '12:35', stop: '12:00', aDenumire: 'fdfff ffff fffhghhhhh', aCod: 'dfffff', fDenumire: '33333333333333 4444444443333333 333333', fCod: '77777777777777', fTimp: '34.009', bRealizate: '345' },
      { crt: '0', status: '[NONE]', orar: 'orar selectie ok3', start: '12:35', stop: '12:00', aDenumire: 'fdfff ffff fffhghhhhh hhhhhhhhhhhhhh hhhhhhhhhhhhhh', aCod: 'dfffff', fDenumire: '33333333333333 4444444443333333 333333', fCod: '77777777777777', fTimp: '34.009', bRealizate: '345' },
      { crt: '0', status: '[NONE]', orar: 'orar selectie ok4', start: '12:35', stop: '12:00', aDenumire: 'fdfff ffff fffhghhhhh', aCod: 'dfffff', fDenumire: '33333333333333 4444444443333333 333333', fCod: '77777777777777', fTimp: '34.009', bRealizate: '345' },
      { crt: '0', status: '[NONE]', orar: 'orar selectie ok5', start: '12:35', stop: '12:00', aDenumire: 'fdfff ffff fffhghhhhh', aCod: 'dfffff', fDenumire: '33333333333333 4444444443333333 333333', fCod: '77777777777777', fTimp: '34.009', bRealizate: '345' },
    ];
    this.getPunctDeLucru();
    this.getStatusLista();
  }


  ptChange() {
    if (!this.ptSelection) { return }
    this.getAngajatLista(this.ptSelection.id);
    this.getOrar(this.ptSelection.id);
  }

  cauta() { }

  onAngajatPrev() {
    console.log("prev!");
  }
  onAngajatNext() {
    console.log("next!");
  }

  btPauzaCautare() {
    if (!this.orarSelection) { return; }
    this.getPauzaList(this.orarSelection.id);
    this.dialogViewPauza = true;
  }


  btArticolCautare() {
    this.articol = new Articol(0, "-", "-");
    this.faza = new Faza(0, "-", "-", 0, 0);
    this.articolSelection = null;
    this.fazaSelection = null;
    this.dialogArticolCautare = true;
    this.getArticolList();
  }

  btArticolCautareConfirmare() {
    if (!this.articolSelection) { return; }
    this.articol = new Articol(this.articolSelection.id, this.articolSelection.denumire, this.articolSelection.cod);
    this.faza = new Faza(0, "-", "-", 0, 0);
    this.getFazaList(this.articolSelection.id);
  }

  btArticolCautareRevocare() {
    this.articolSelection = null;
    this.fazaSelection = null;
    this.faza = new Faza(0, "-", "-", 0, 0);
    this.articol = new Articol(0, "-", "-");

  }


  btFazaCautare() {
    this.faza = new Faza(0, "-", "-", 0, 0);
    this.fazaSelection = null;
    if (!this.articolSelection) { return; }
    this.dialogFazaCautare = true;
  }

  btFazaCautareConfirmare() {
    if (!this.fazaSelection) { return; }
    this.faza = new Faza(this.fazaSelection.id, this.fazaSelection.denumire, this.fazaSelection.cod, this.fazaSelection.timp, this.articolSelection.id);

  }

  btFazaCautareRevocare() {
    this.fazaSelection = null;
    this.faza = new Faza(0, "-", "-", 0, 0);
  }



  btIntroducere() {


    if (!this.ptSelection) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Punctul de Lucru nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.angajatSelection) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Angajatul nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.dataIntroducerii) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Data Intoducerii nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.orarSelection) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Orarul nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.statusSelection) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Statusul nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.articolSelection) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Articolul nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.fazaSelection) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Faza nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.start) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Timpul Start nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.stop) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Timpul Stop nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if ((this.start == "00:00") && (this.stop == "00:00")) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Timpul Start - Stop nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }


//    this.statusDialog='dialogSuccess';
 //   this.statusDialogMessage=["Inregistrarea a fost efectuata cu Success !"];


    this.statusDialog='dialogServerError';
    this.statusDialogMessage=["Erroare Timpul selectat nu este corect, djdjdj djdjhdj djhdjhdj dhdhdh"];



  }


  //=========================== API !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!==========================

  getPunctDeLucru() {
    this.pt_load = true;
    this.dbs.ApiPunctdelucruList()
      .subscribe(data => {
        this.pt_load = false;
        this.ptDataSource = data;
      }, error => {
        this.pt_load = false;
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      });

  }
  getAngajatLista(id_plucru: number) {
    this.angajatDataSource = [];
    this.angajat_load = true;
    this.dbs.ApiAngajatiList(id_plucru)
      .subscribe(data => {
        this.angajatDataSource = data;
        this.angajat_load = false;

      }, error => {
        this.angajat_load = false;
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      });
  }

  getStatusLista() {
    this.status_load = true;
    this.dbs.ApiStatusList()
      .subscribe(data => {
        this.statusDataSource = data;
        this.status_load = false;
      }, error => {
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
        this.status_load = false;
      });
  }

  getOrar(id_plucru: number) {
    this.orar_load = true;
    this.dbs.ApiOrarList(id_plucru)
      .subscribe(data => {
        this.orar_load = false;
        this.orarDataSource = data;
      }, error => {
        this.orar_load = false;
        this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
      });
  }
  getPauzaList(id_orar: number) {

    this.pauza_load = true;
    this.pauzaDataSource = null;
    this.dbs.ApiPauzaList(id_orar)
      .subscribe(data => {
        this.pauzaDataSource = data;
        this.pauza_load = false;

      }, error => {
        this.pauza_load = false;

      });



  }




  getArticolList() {
    this.articol_load = true;
    this.articolDataSource = null;
    this.dbs.ApiArticolList()
      .subscribe(data => {
        this.articolDataSource = data;
        this.articol_load = false;

      }, error => {
        this.articol_load = false;

      });

  }

  getFazaList(id_articol: number) {
    this.faza_load = true;
    this.fazaDataSource = null;
    this.dbs.ApiFazaList(id_articol)
      .subscribe(data => {
        this.fazaDataSource = data;
        this.faza_load = false;

      }, error => {
        this.faza_load = false;

      });

  }

}
