export class Faza {
    id:number;
    denumire:string;
    cod:string;
    timp:number;
    id_articol:number;

    constructor(id:number,denumire:string,cod:string,timp,id_articol:number){
        this.id=id;
        this.denumire=denumire;
        this.cod=cod;
        this.timp=timp;
        this.id_articol=id_articol;
    }
}

