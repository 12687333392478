import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Pages/Home/home/home.component';
import { AngajatiListaComponent } from './Pages/Angajati/angajati-lista/angajati-lista.component';
import { CalculatorOreComponent } from './Pages/Calculator/calculator-ore/calculator-ore.component';
import { ArticolSiFazaListComponent } from './Pages/Articol/articol-si-faza-list/articol-si-faza-list.component';
import { OrarListComponent } from './Pages/Orar/orar-list/orar-list.component';
import { ConfiguratieListComponent } from './Pages/Configuratie/configuratie-list/configuratie-list.component';
import { PunctDeLucruListaComponent } from './Pages/PunctDeLucru/punct-de-lucru-lista/punct-de-lucru-lista.component';
import { AuthGuardService as AuthGuard } from './Service/AuthGuard/auth-guard.service';
import { LoginPageComponent } from './Pages/Login/login-page/login-page.component';
import { DashBoardComponent } from './Pages/DashBoard/dash-board/dash-board.component';
import { CalculatorPageComponent } from './Pages/Calculator/calculator-page/calculator-page.component';
import { ProductiePageComponent } from './Pages/Productie/productie-page/productie-page.component';
import { NormaProductiePageComponent } from './Pages/Productie/norma-productie-page/norma-productie-page.component';
import { RaportProductiePageComponent } from './Pages/Productie/raport-productie-page/raport-productie-page.component';
import { StatusComponent } from './Pages/Status/status/status.component';
import { LogoutPageComponent } from './Pages/Logout/logout-page/logout-page.component';
import { UtilizatorListaComponent } from './Pages/Utilizator/utilizator-lista/utilizator-lista.component';

const routes: Routes = [
  { path: '', component: DashBoardComponent,  canActivate: [AuthGuard],
    children: [
      { path: '', component:HomeComponent  },
      { path: 'Home', component:HomeComponent  },
      { path: 'Angajati-Lista', component: AngajatiListaComponent },
      { path: 'Calculator', component: CalculatorPageComponent},
      { path: 'Productie', component: ProductiePageComponent},
      { path: 'NormaProductie', component: NormaProductiePageComponent},
      { path: 'RaportProductie', component: RaportProductiePageComponent},
      { path: 'Calculator-Ore', component: CalculatorOreComponent  },
      { path: 'ArticoleSiFaza', component: ArticolSiFazaListComponent},
      { path: 'OrarConfig', component: OrarListComponent},
      { path: 'Configuratie', component: ConfiguratieListComponent},
      { path: 'PunctDeLucru', component: PunctDeLucruListaComponent},
      { path: 'Status-Lista', component: StatusComponent},
      { path: 'Users', component: UtilizatorListaComponent},
    
    ]

  },
  { path: 'Logout', component: LogoutPageComponent },
  { path: 'Login', component: LoginPageComponent },
  { path: '', redirectTo: '/Login', pathMatch: 'full'}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
