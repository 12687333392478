import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { NavigationService } from 'src/app/Service/Navigation/navigation.service';
import { LoginService } from 'src/app/Service/Login/login.service';

@Component({
  selector: 'app-meniu-top',
  templateUrl: './meniu-top.component.html',
  styleUrls: ['./meniu-top.component.css']
})
export class MeniuTopComponent implements OnInit {

  items: MenuItem[];
  utilizator_nume:string;

  constructor(private myNavBar: NavigationService, private userAccess: LoginService) {
    
  }

  breadcrumb: MenuItem[];

  ngOnInit() {
    this.userAccess.verifyToken()
      .subscribe(data => {
        this.utilizator_nume = data.nume_prenume;
        console.log(data);
      }
        , error => {
          this.utilizator_nume = 'Unknow';
      });















    this.items = [
      { label: 'Home', icon: 'pi pi-fw pi-plus', routerLink: "/Home" },
      { label: 'Caculator', icon: 'pi pi-fw pi-download', routerLink: "/Calculator" },
      { label: 'Productie', icon: 'pi pi-fw pi-refresh', routerLink: "/Productie" },
      { label: 'Configuratie', icon: 'pi pi-fw pi-refresh', routerLink: "/Configuratie" }
    ];

    /*  this.breadcrumb = [
       { label: 'Home', routerLink: "/Home" },
       { label: 'Sports' },
       { label: 'Squad' },
       { label: 'Lionel Messi', routerLink: "/Configuratie" }
     ]; */

    this.breadcrumb = [
      { label: 'Home', routerLink: "/Home" }
    ];

    this.myNavBar.navBar$.subscribe(
      message => {
        console.log("megkaptam!");
        this.breadcrumb = message;
        console.log(message);
      }
    );




  }




}
