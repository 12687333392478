import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngajatiListaComponent } from './Pages/Angajati/angajati-lista/angajati-lista.component';
import { MeniuLeftComponent } from './Components/meniu-left/meniu-left.component';
import { MeniuTopComponent } from './Components/meniu-top/meniu-top.component';
import { HomeComponent } from './Pages/Home/home/home.component';
import { CalculatorOreComponent } from './Pages/Calculator/calculator-ore/calculator-ore.component';
import { PunctDeLucruListaComponent } from './Pages/PunctDeLucru//punct-de-lucru-lista/punct-de-lucru-lista.component';


import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {ButtonModule} from 'primeng/button';
import {MessageService} from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {PanelModule} from 'primeng/panel';
import {DropdownModule} from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {CalendarModule} from 'primeng/calendar';
import {TabViewModule} from 'primeng/tabview';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {DialogModule} from 'primeng/dialog';
import {FieldsetModule} from 'primeng/fieldset';
import {InputTextModule} from 'primeng/inputtext';
import {DataViewModule} from 'primeng/dataview';
import {MenuModule} from 'primeng/menu';
import { ArticolSiFazaListComponent } from './Pages/Articol/articol-si-faza-list/articol-si-faza-list.component';
import { OrarListComponent } from './Pages/Orar/orar-list/orar-list.component';
import { ConfiguratieListComponent } from './Pages/Configuratie/configuratie-list/configuratie-list.component';
import {CardModule} from 'primeng/card';
import { LoginPageComponent } from './Pages/Login/login-page/login-page.component';
import { LogoutPageComponent } from './Pages/Logout/logout-page/logout-page.component';
import { DashBoardComponent } from './Pages/DashBoard/dash-board/dash-board.component';
import { CalculatorPageComponent } from './Pages/Calculator/calculator-page/calculator-page.component';
import { ProductiePageComponent } from './Pages/Productie/productie-page/productie-page.component';
import { NormaProductiePageComponent } from './Pages/Productie/norma-productie-page/norma-productie-page.component';
import { RaportProductiePageComponent } from './Pages/Productie/raport-productie-page/raport-productie-page.component';
import { StatusComponent } from './Pages/Status/status/status.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {CheckboxModule} from 'primeng/checkbox';
import {ProgressBarModule} from 'primeng/progressbar';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ToolbarModule} from 'primeng/toolbar';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { UtilizatorListaComponent } from './Pages/Utilizator/utilizator-lista/utilizator-lista.component';
import { StatusDialogComponent } from './StatusDialog/status-dialog/status-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    AngajatiListaComponent,
    
    MeniuLeftComponent,
    MeniuTopComponent,
    HomeComponent,
    CalculatorOreComponent,
    ArticolSiFazaListComponent,
    OrarListComponent,
    ConfiguratieListComponent,
    PunctDeLucruListaComponent,
    LoginPageComponent,
    LogoutPageComponent,
    DashBoardComponent,
    CalculatorPageComponent,
    ProductiePageComponent,
    NormaProductiePageComponent,
    RaportProductiePageComponent,
    StatusComponent,
    UtilizatorListaComponent,
    StatusDialogComponent
    
    
    
    
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    TableModule,
    ToastModule,
    ButtonModule,
    BrowserAnimationsModule,
    PanelModule,
    DropdownModule,
    FormsModule,
    ProgressSpinnerModule,
    CalendarModule,
    TabViewModule,
    ToggleButtonModule,
    DialogModule,
    FieldsetModule,
    InputTextModule,
    DataViewModule,
    CardModule,
    MenuModule,
    ScrollPanelModule,
    CheckboxModule,
    ProgressBarModule,
    AutoCompleteModule,
    ToolbarModule,
    BreadcrumbModule,
    MessagesModule,
    MessageModule
    
  ],
  providers: [MessageService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
