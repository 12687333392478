import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../Login/login.service';
import { Observable, of } from 'rxjs';
import { map,  catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private loginservice: LoginService) {  }


  canActivate(): Observable<boolean>  {
    console.log("-------------->AUTHGUARD IS RUNNING !!!!");
    return this.loginservice.verifyToken().pipe(
      map(res => {
        if (res['Error']) {
          console.log("Resource error");
          this.router.navigate(['/Login']);
          return false;
        } else {
          console.log("its ok true");
          return true;
        }
      }),
      catchError((err) => {
        console.log("Unknow Error!");
        this.router.navigate(['/Login']);
        return of(false);
      })
    );
    



     
      
  }

}