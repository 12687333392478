import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/Service/Database/databaseserv.service';
import { Articol } from 'src/app/model/articol';
import { Faza } from 'src/app/model/faza';
import { MessageService } from 'primeng/api';
import { CalculatorOre } from 'src/app/model/calculator-ore';
import { Rezultat } from 'src/app/model/rezultat';



@Component({
  selector: 'app-calculator-ore',
  templateUrl: './calculator-ore.component.html',
  styleUrls: ['./calculator-ore.component.css']
})
export class CalculatorOreComponent implements OnInit {

  definedPt: boolean = true;

  //Punct de Lucru
  ptDataSource: any;
  ptSelection: any;
  pt_load: boolean = false;
  pt_need: boolean = true;

  //Angjati
  angajatDataSource: any;
  angajatSelection: any;
  angajatCols: any;
  angajat_load: boolean = false;

  //Orar
  orarDataSource: any;
  orarSelection: any;
  orar_load: boolean = false;

  //Articol
  articolDataSource: any;
  articolCols: any;
  articolSelection: any = null;
  articol_load: boolean = false;

  //Faza
  fazaDataSource: any;
  fazaCols: any;
  fazaSelection: any = null;
  faza_load: boolean = false;

  //pauza
  pauzaDataSource: any;
  pauzaCols: any;
  pauza_load:boolean=false;

  // OBject;
  articol: Articol;
  faza: Faza;

  //Taburi
  tabIndex: number = 0;

  //DIALOG
  dialogAngajatCautare: boolean = false;
  dialogArticolCautare: boolean = false;
  dialogFazaCautare: boolean = false;
  dialogViewPauza:boolean=false;

  //VALIDARE DIALOG !
  statusDialog: string;
  statusDialogMessage: Array<String>;



  // VARIABILE 
  start:string="00:00";
  stop:string="00:00";
  night:boolean=false;
  
  myError:any;
  myCalculatorOre:CalculatorOre;

  rezultat:Rezultat;

  constructor(private myMessage: MessageService, private dbs: DatabaseService) {
    this.angajatCols = [
      { field: 'nume_prenume', header: 'Nume Prenume' },
      { field: 'cnp', header: 'Cnp' },
      { field: 'localitate', header: 'Localitate' }
    ];

    this.articolCols = [
      { field: 'denumire', header: 'Articol Denumire' },
      { field: 'cod', header: 'Cod Articol' }
    ];
    
    this.fazaCols = [
      { field: 'denumire', header: 'Faza Denumire' },
      { field: 'cod', header: 'Faza Cod' },
      { field: 'timp', header: 'Faza Timp' }
    ];

    this.pauzaCols = [
      { field: 'denumire', header: 'denumire' },
      { field: 'start', header: 'start' },
      { field: 'stop', header: 'stop' },
      { field: 'night', header: 'night' },
      { field: 'timp_pauza', header: 'pauza' }
    ];

    this.articol = new Articol(0, "-", "-");
    this.faza = new Faza(0, "-", "-", 0, 0);
    this.definedPt=false;
    this.rezultat=new Rezultat("-","-","-","-","-","-","-","-","-","-","-","-");
    this.statusDialogMessage=[];
  }

  ngOnInit() {
    //if getSecurityApp()

    if (this.definedPt) {
      this.getAngajatiList(1);
      this.getOrarList(1);
    } else {
      this.getPunctDeLucruList();
    }

  }

  onPtChange() {
    if (!this.ptSelection) {return;}
    this.getAngajatiList(this.ptSelection.id);
    this.getOrarList(this.ptSelection.id);
  }

  


  btPauzaCautare(){
    if (!this.orarSelection){return;}
    this.getPauzaList(this.orarSelection.id);
    this.dialogViewPauza=true;
  }


  btArticolCautare() {
    this.articol = new Articol(0, "-", "-");this.articolSelection=null;
    this.faza = new Faza(0, "-", "-", 0, 0);this.fazaSelection=null;
    this.dialogArticolCautare = true;
    this.getArticolList();
  }

  btArticolCautareConfirmare() {
    if (!this.articolSelection) {return;}
    this.articol = new Articol(this.articolSelection.id, this.articolSelection.denumire, this.articolSelection.cod);
    this.faza = new Faza(0, "-", "-", 0, 0);
    this.getFazaList(this.articolSelection.id);
  }

  btArticolCautareRevocare(){
    this.articolSelection=null;this.articol = new Articol(0, "-", "-");
    this.fazaSelection=null;this.faza = new Faza(0, "-", "-", 0, 0);
  }


  btFazaCautare() {
    this.fazaSelection=null;this.faza = new Faza(0, "-", "-", 0, 0);
    if (!this.articolSelection) {return;}
    this.dialogFazaCautare = true;
  }

  btFazaCautareConfirmare() {
    if (!this.fazaSelection) {return;}
    this.faza = new Faza(this.fazaSelection.id,this.fazaSelection.denumire,this.fazaSelection.cod,this.fazaSelection.timp,this.articolSelection.id);

  }

  btFazaCautareRevocare(){
    this.fazaSelection=null;this.faza = new Faza(0, "-", "-", 0, 0);
  }
  


  btnCalculeazaProductia(){

    if (!this.ptSelection) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Punctul de Lucru nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.angajatSelection) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Angajatul nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.orarSelection) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Orarul nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.articolSelection) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Articolul nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.fazaSelection) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Faza nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.start) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Timpul Start nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if (!this.stop) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Timpul Stop nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }
    if ((this.start == "00:00") && (this.stop == "00:00")) { this.statusDialog = 'dialogError'; this.statusDialogMessage = ["Erroare - Timpul Start - Stop nu este selectat !", "Va rugam sa selectati optiunea lipsa ..."]; return; }


    console.log("indul a calcul php !");
    this.myCalculatorOre= new CalculatorOre(this.ptSelection.id,this.angajatSelection.id,this.orarSelection.id,this.articolSelection.id, this.fazaSelection.id,this.night,this.start,this.stop);
    this.dbs.ApiCalculatorOrePut(this.myCalculatorOre)
      .subscribe(data => {
        this.statusDialog = 'dialogSuccess'; 
        this.statusDialogMessage = ["Calculele efectuate au fost corecte, vezi rezultatul...",data.Message]; 
        this.rezultat=new Rezultat(data.ArticolDenumire,data.ArticolCod,data.FazaDenumire,data.FazaCod,data.FazaTimp,data.Interval,data.SchimbNoapte,data.Total,data.Lucru,data.Pauza,data.BucatiCerute,data.BucatiRealizate);
        this.tabIndex=1;
      }, error => {
        this.rezultat=new Rezultat("-","-","-","-","-","-","-","-","-","-","-","-");
       // this.myMessage.add({ severity: 'error', summary: 'Eroare de comunicare cu serverul', detail: error.status + " " + error.error.Message, life: 5000, closable: false });
       this.statusDialog = 'dialogServerError'; 
//       this.statusDialogMessage = ["Calculele Eronate, "+error.status+" "+error.error.Message]; 
       this.statusDialogMessage = error.error.Message; 

      });
     
 
    

  }

  
  cauta(){
    
  }


  //============================================ API =================================================
  getOrarList(id_plucru: number) {
    this.orar_load = true;
    this.orarSelection=null;
    this.orarDataSource=null;
    this.dbs.ApiOrarList(id_plucru)
      .subscribe(data => {
        this.orarDataSource = data;
        this.orar_load = false;

      }, error => {
        this.statusDialog = 'dialogServerUnexcept'; 
        this.statusDialogMessage =["Error CODE:  "+error.status,"Erorr Message:  "+error.error.Message]; 
      });
  }


  getPunctDeLucruList() {
    this.pt_load = true;
    this.ptDataSource=null;
    this.dbs.ApiPunctdelucruList()
      .subscribe(data => {
        this.ptDataSource = data;
        this.pt_load = false;

      }, error => {
        this.statusDialog = 'dialogServerUnexcept'; 
        this.statusDialogMessage =["Error CODE:  "+error.status,"Erorr Message:  "+error.error.Message]; 

      });
  }

  getAngajatiList(id_plucru: number) {
    this.angajat_load = true;
    this.angajatDataSource=null;
    this.dbs.ApiAngajatiList(id_plucru)
      .subscribe(data => {
        this.angajatDataSource = data;
        this.angajat_load = false;

      }, error => {
        this.statusDialog = 'dialogServerUnexcept'; 
        this.statusDialogMessage =["Error CODE:  "+error.status,"Erorr Message:  "+error.error.Message]; 
      });

  }
  getPauzaList(id_orar:number){

    this.pauza_load = true;
    this.pauzaDataSource=null;
    this.dbs.ApiPauzaList(id_orar)
      .subscribe(data => {
        this.pauzaDataSource = data;
        this.pauza_load = false;

      }, error => {
        this.statusDialog = 'dialogServerUnexcept'; 
        this.statusDialogMessage =["Error CODE:  "+error.status,"Erorr Message:  "+error.error.Message]; 

      });



  }




  getArticolList() {
    this.articol_load = true;
    this.articolDataSource=null;
    this.dbs.ApiArticolList()
      .subscribe(data => {
        this.articolDataSource = data;
        this.articol_load = false;
      }, error => {
        this.statusDialog = 'dialogServerUnexcept'; 
        this.statusDialogMessage =["Error CODE:  "+error.status,"Erorr Message:  "+error.error.Message]; 
      });

  }

  getFazaList(id_articol:number) {
    this.faza_load = true;
    this.fazaDataSource=null;
    this.dbs.ApiFazaList(id_articol)
      .subscribe(data => {
        this.fazaDataSource = data;
        this.faza_load = false;
      }, error => {
        this.statusDialog = 'dialogServerUnexcept'; 
        this.statusDialogMessage =["Error CODE:  "+error.status,"Erorr Message:  "+error.error.Message]; 
      });

  }



}
