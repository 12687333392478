import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DatabaseService } from 'src/app/Service/Database/databaseserv.service';
import { Articol } from 'src/app/model/articol';
import { Faza } from 'src/app/model/faza';

@Component({
  selector: 'app-articol-si-faza-list',
  templateUrl: './articol-si-faza-list.component.html',
  styleUrls: ['./articol-si-faza-list.component.css']
})
export class ArticolSiFazaListComponent implements OnInit {

  articolDataSource: any;
  articolSelection: any;
  articolCols: any;
  articol: Articol;
  load_data_articol: boolean;

  fazaDataSource: any;
  fazaSelection: any;
  fazaCols: any;
  faza: Faza;
  load_data_faza: boolean;



  // ARTICOL SHOW


  // ARTICOL DISPLAY
  displayDenumire: string = '[NONE]';
  displayCod: string = '[NONE]';


  // ARTICOL DIALOG
  dialogArticolActionAdd: boolean;
  dialogArticolActionEdit: boolean;
  dialogArticolActionDelete: boolean;

  // Faza DIALOG
  dialogFazaActionAdd: boolean;
  dialogFazaActionEdit: boolean;
  dialogFazaActionDelete: boolean;




  constructor(private myMessage: MessageService, private dbs: DatabaseService) {
    this.articolCols = [
      { field: 'denumire', header: 'Articol Denumire' },
      { field: 'cod', header: 'Cod Articol' }
    ];
    this.articol = new Articol(0, "", "");


    this.fazaCols = [
      { field: 'denumire', header: 'Faza Denumire' },
      { field: 'cod', header: 'Faza Cod' },
      { field: 'timp', header: 'Faza Timp' }
    ];
    this.faza = new Faza(0, "", "", 0, 0);


  }

  ngOnInit() {
    this.getArticole();

  }

  
  articolTableButtonClick(val: string) {
    if (val === "Add") {
      this.articol = new Articol(0, '', '');
      this.dialogArticolActionAdd = true;
    }
    if (val === "Edit") {
      if (this.articolSelection == null) { this.myMessage.add({ severity: 'warn', summary: 'Articol Selectie', detail: "Nu ati selectat nici un Articol pentru actiuninea Modifica !", life: 5000 }); return; }
      if (this.articolSelection.denumire === '[NONE]') { this.myMessage.add({ severity: 'info', summary: 'Articol Selectie Special', detail: "[Null] este element special care nu poate fii Modificat !", life: 5000 }); return; }

      this.articol = new Articol(this.articolSelection.id, this.articolSelection.denumire, this.articolSelection.cod);
      this.dialogArticolActionEdit = true;
    }
    if (val === "Delete") {
      if (this.articolSelection == null) { this.myMessage.add({ severity: 'warn', summary: 'Articol Selectie', detail: "Nu ati selectat nici un Articol pentru actiunea Modifica !", life: 5000 }); return; }
      if (this.articolSelection.denumire === '[NONE]') { this.myMessage.add({ severity: 'info', summary: 'Articol Selectie Special', detail: "[Null] este element special care nu poate fii Sters !", life: 5000 }); return; }

      this.articol = new Articol(this.articolSelection.id, this.articolSelection.denumire, this.articol.cod);
      this.dialogArticolActionDelete = true;
    }
  }

  fazaTableButtonClick(val: string) {
    if (val === "Add") {
      if (this.articolSelection.denumire === '[NONE]') { this.myMessage.add({ severity: 'info', summary: 'Faza Selectie Special', detail: "[Null] este element special nu poate fii Adaugat elemnte !", life: 5000 }); return; }

      this.faza = new Faza(0, '', '', 0, 0);
      this.dialogFazaActionAdd = true;
    }

    if (val === "Edit") {

      if (this.fazaSelection == null) { this.myMessage.add({ severity: 'warn', summary: 'Faza Selectie', detail: "Nu ati selectat nici o Faza pentru actiuninea Modifica !", life: 5000 }); return; }
      if (this.articolSelection.denumire === '[NONE]') { this.myMessage.add({ severity: 'info', summary: 'Faza Selectie Special', detail: "[Null] este element special nu poate fii Sters sau Modificat !", life: 5000 }); return; }

      this.faza = new Faza(this.fazaSelection.id, this.fazaSelection.denumire, this.fazaSelection.cod, this.fazaSelection.timp, this.articolSelection.id);
      this.dialogFazaActionEdit = true;

    }
    if (val === "Delete") {

      if (this.fazaSelection == null) { this.myMessage.add({ severity: 'warn', summary: 'Faza Selectie', detail: "Nu ati selectat nici o Faza pentru actiunea Sterge !", life: 5000 }); return; }
      if (this.fazaSelection.denumire === '[NONE]') { this.myMessage.add({ severity: 'info', summary: 'Faza Selectie Special', detail: "[Null] este element special nu poate fii Sters !", life: 5000 }); return; }

      this.faza = new Faza(this.fazaSelection.id, this.fazaSelection.denumire, this.fazaSelection.cod, this.faza.timp, this.articolSelection.id);
      this.dialogFazaActionDelete = true;
    }
  }








  onArticolRowSelect() {
    this.displayDenumire = this.articolSelection.denumire;
    this.displayCod = this.articolSelection.cod;
    this.getFaze(this.articolSelection.id);
  }

  onArticolRowUnselect() {
    this.displayDenumire = '[NONE]';
    this.displayCod = '[NONE]';
  }

  //===================================== DIALOGS EVENT ARTICOL ==============================================
  onAdaugaArticolClick(articol: Articol) {
    console.log("Adauga Articol:" + this.articol.id);
  }
  onEditArticolClick(articol: Articol) {
    console.log("edit Articol:" + this.articol.id);
  }

  onDeleteArticolClick(articol: Articol) {
    console.log("Sterge Articol:" + this.articol.id);
  }



  //===================================== DIALOGS EVENT FAZA ==============================================
  onAdaugaFazaClick(faza: Faza) {
    console.log("Adauga faza:" + this.faza.id);
  }
  onEditFazaClick(faza: Faza) {
    console.log("edit faza:" + this.faza.id);
  }

  onDeleteFazaClick(faza: Faza) {
    console.log("Sterge faza:" + this.faza.id);
  }




  //===================================== API ==============================================

  getArticole() {
    this.load_data_articol = true;
    this.dbs.ApiArticolList()
      .subscribe(data => {
        this.articolDataSource = data;
        this.load_data_articol = false;

      }, error => {
        this.load_data_articol = false;
      });
  }

  getFaze(id_articol: number) {
    this.fazaDataSource = [];
    this.load_data_faza = true;
    this.dbs.ApiFazaList(id_articol)
      .subscribe(data => {
        this.fazaDataSource = data;
        this.load_data_faza = false;

      }, error => {
        this.load_data_faza = false;
      });
  }



}
