import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/Service/Login/login.service';

@Component({
  selector: 'app-configuratie-list',
  templateUrl: './configuratie-list.component.html',
  styleUrls: ['./configuratie-list.component.css']
})
export class ConfiguratieListComponent implements OnInit {


  p_angjatiLista: boolean;
  p_statusLista: boolean;
  p_plucruLista: boolean;
  p_orarLista: boolean;
  p_articolLista: boolean;
  p_utilizatorLista: boolean;


  constructor(private userAccess: LoginService) { }

  ngOnInit() {
    this.get_role();
  }

  get_role() {
    this.p_angjatiLista = false;
    this.p_statusLista = false;
    this.p_plucruLista = false;
    this.p_orarLista = false;
    this.p_articolLista = false;
    this.p_utilizatorLista = false;

    this.userAccess.verifyToken()
      .subscribe(data => {
        console.log(data.role);
        switch (data.role) {
          //user role
          case "1": 
            this.p_angjatiLista = false;
            this.p_statusLista = false;
            this.p_plucruLista = false;
            this.p_orarLista = false;
            this.p_articolLista = false;
            this.p_utilizatorLista = false;
            break;
          
          //superuser role
          case "2": 
            this.p_angjatiLista = true;
            this.p_statusLista = true;
            this.p_plucruLista = true;
            this.p_orarLista = true;
            this.p_articolLista = true;
            this.p_utilizatorLista = false;
            break;
          
          //admin Role
          case "3": 
            this.p_angjatiLista = true;
            this.p_statusLista = true;
            this.p_plucruLista = true;
            this.p_orarLista =  true;
            this.p_articolLista = true;
            this.p_utilizatorLista = true;
            break;
          
        }
      }
        , error => {

        });




  }


}
