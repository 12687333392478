import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productie-page',
  templateUrl: './productie-page.component.html',
  styleUrls: ['./productie-page.component.css']
})
export class ProductiePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
