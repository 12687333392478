import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.css']
})
export class StatusDialogComponent implements OnChanges {
  @Input() status: string;
  @Input() statusMessage:Array<string>;
  @Output() statusChange = new EventEmitter<string>(); 

  dialogSuccess: boolean;
  dialogError:boolean;
  dialogServerError:boolean;
  dialogServerUnexcept:boolean;


  constructor() {  }

  ngOnChanges(){
    console.log("On Changes Lefutott");
 
    switch (this.status) {
       case 'dialogSuccess':{ this.dialogSuccess=true;  break;  }
       case 'dialogError':{ this.dialogError=true;  break;  }
       case 'dialogServerError':{ this.dialogServerError=true;  break;  }
       case 'dialogServerUnexcept':{ this.dialogServerUnexcept=true;  break;  }
    }
      
  } 

  onClose(){
    this.statusChange.emit('');
  }

  

}
