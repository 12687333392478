
export class Rezultat {

    ArticolDenumire:string;
    ArticolCod:string;
    FazaDenumire:string;
    FazaCod:string;
    FazaTimp:string;
    Interval:string;
    SchimbNoapte:string;
    Total:string;
    Lucru:string;
    Pauza:string;
    BucatiCerute:string;
    BucatiRealizate:string;

    constructor(ArticolDenumire,ArticolCod,FazaDenumire,FazaCod,FazaTimp,Interval,SchimbNoapte,Total,Lucru,Pauza,BucatiCerute,BucatiRealizate){

        this.ArticolDenumire=ArticolDenumire;
        this.ArticolCod=ArticolCod;
        this.FazaDenumire=FazaDenumire;
        this.FazaCod=FazaCod;
        this.FazaTimp=FazaTimp;
        this.Interval=Interval;
        this.SchimbNoapte=SchimbNoapte;
        this.Total=Total;
        this.Lucru=Lucru;
        this.Pauza=Pauza;
        this.BucatiCerute=BucatiCerute;
        this.BucatiRealizate=BucatiRealizate;
    }
}
