import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/Service/Login/login.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor( private loginservice:LoginService) { }

  ngOnInit() {

    this.loginservice.verifyToken().subscribe(data => {
      //this.puncturidelucru = data;
      console.log(data);
    }, error => {
      console.log(error);

    });

  }

}
