export class Articol {
    id:number;
    denumire:string;
    cod:string;

    constructor(id:number,denumire:string,cod:string){
        this.id=id;
        this.denumire=denumire;
        this.cod=cod;
    }
}
