import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { LoginService } from 'src/app/Service/Login/login.service';
import { Router } from '@angular/router';

interface ILogin{
  username:string;
  password:string;
  Token:string;
  Message:string;
}

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  loginForm:any;
  formMessage:string; 
  loginError:boolean=false;
  username:string;
  password:string;
  login_load:boolean=false;

  constructor(private loginService:LoginService, private router:Router) { }

  ngOnInit() {  
    sessionStorage.setItem("Token","");
   }

  

onLogin(){
  this.login_load=true;
  this.loginError=false;
  console.log("on Login");
  this.loginService.login(this.username,this.password)
  .subscribe(data => {
    this.login_load=false;
    this.formMessage="";
    this.loginError=false;
    sessionStorage.setItem("Token", data.Token);
    this.router.navigate(['/Home']);
    console.log(data);
  }
    , error => {
      this.login_load=false;
      console.log("LOGIN EROOR !!!");
      this.loginError=true;
      this.formMessage=error.error.Message;
      this.username="";
      this.password="";
    }); 
  
}


}
