import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-utilizator-lista',
  templateUrl: './utilizator-lista.component.html',
  styleUrls: ['./utilizator-lista.component.css']
})
export class UtilizatorListaComponent implements OnInit {

  load_utilizator:boolean=false;

  constructor() { }

  ngOnInit() {
  }

}
