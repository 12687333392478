import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/Service/Database/databaseserv.service';
import { MessageService } from 'primeng/api';
import { Status } from 'src/app/model/status';


@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})


export class StatusComponent implements OnInit {

  //table
  load: boolean = false;
  statusDataSource: any;
  statusCol: any;
  statusSelection: any = null;

  //dialogs
  dialogActionAdd: boolean;
  dialogActionEdit: boolean;
  dialogActionDelete: boolean;

  //class
  myStatus: Status;


  //VALIDARE DIALOG !
  statusDialog: string;
  statusDialogMessage: Array<String>;


  constructor(private dbs: DatabaseService, private myMessage: MessageService) {
    this.statusCol = [{ field: 'status_de_lucru', header: 'Status de Lucru' }];
    this.myStatus = new Status(0, '');
  }



  ngOnInit() {
    this.statusTableLoad();
  }

  statusTableButton(val: string) {
    switch (val) {

      case "Add": {
        this.myStatus = new Status(0, '');
        this.dialogActionAdd = true;
        break;
      }

      case "Edit": {
        if (!this.statusSelection) { return; }
        if (this.statusSelection.status_de_lucru == "[NONE]") {
          this.statusDialog = 'dialogError';
          this.statusDialogMessage = ['Salut statusul [NONE] este mai special si nu se poate Modifica !', 'Ne cerem scuze :)'];
          return;
        }

        this.myStatus = new Status(this.statusSelection.id, this.statusSelection.status_de_lucru);
        this.dialogActionEdit = true;
        break;
      }

      case "Delete": {
        if (!this.statusSelection) { return; }
        if (this.statusSelection.status_de_lucru == "[NONE]") {
          this.statusDialog = 'dialogError';
          this.statusDialogMessage = ['Salut statusul [NONE] este mai special si nu se poate Sterge !', 'Ne cerem scuze :)'];
          return;
        }
        this.myStatus = new Status(this.statusSelection.id, this.statusSelection.status_de_lucru);
        this.dialogActionDelete = true;
        break;
      }
    }

  }



  onAdaugaClick(myStatus: Status) {
    this.statusAdd(myStatus);
  }


  onEditClick(myStatus: Status) {
    this.statusEdit(myStatus);
  }


  onDeleteClick(myStatus: Status) {
    this.statusDelete(myStatus);
  }



  //======================================== API ==========================

  statusTableLoad() {
    this.load = true;
    this.statusSelection = null;
    this.dbs.ApiStatusList()
      .subscribe(data => {
        this.statusDataSource = data;
        this.load = false;
      }, error => {
        this.statusDialog = 'dialogServerUnexcept';
        this.statusDialogMessage = ["Error CODE:  " + error.status, "Erorr Message:  " + error.error.Message];
      });
  }

  statusAdd(myStatus: Status) {
    this.dbs.ApiStatusAdd(myStatus).subscribe(
      data => {
        this.statusDialog = 'dialogSuccess';
        this.statusDialogMessage = [data.Message];
        this.statusTableLoad();
      },
      error => {
        this.statusDialog = 'dialogServerUnexcept';
        this.statusDialogMessage = ["Error CODE:  " + error.status, "Erorr Message:  " + error.error.Message];
      }
    );

  }


  statusEdit(myStatus: Status) {
    this.dbs.ApiStatusEdit(myStatus).subscribe(
      data => {
        this.statusDialog = 'dialogSuccess';
        this.statusDialogMessage = [data.Message];
        this.statusTableLoad();
      },
      error => {
        this.statusDialog = 'dialogServerUnexcept';
        this.statusDialogMessage = ["Error CODE:  " + error.status, "Erorr Message:  " + error.error.Message];
      }
    );
  }


  statusDelete(myStatus: Status) {
    this.dbs.ApiStatusDelete(myStatus).subscribe(
      data => {
        this.statusDialog = 'dialogSuccess';
        this.statusDialogMessage = [data.Message];
        this.statusTableLoad();
      },
      error => {
        this.statusDialog = 'dialogServerUnexcept';
        this.statusDialogMessage = ["Error CODE:  " + error.status, "Erorr Message:  " + error.error.Message];
      }
    );

  }




}