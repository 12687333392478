import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { DatabaseService } from 'src/app/Service/Database/databaseserv.service';
import { Angajat } from 'src/app/model/angajat';
import { NavigationService } from 'src/app/Service/Navigation/navigation.service';




@Component({
  selector: 'app-angajati-lista',
  templateUrl: './angajati-lista.component.html',
  styleUrls: ['./angajati-lista.component.css']
})
export class AngajatiListaComponent implements OnInit {

  ptDataSource: any;
  ptSelection: any;
  pt_load: boolean = false;

  angajatiDataSource: any;
  angajatiCols: any;
  angajatiSelection: any = null;

  load: boolean = false;

  //Class
  myAngajat: Angajat;

  //Dialog
  dialogActionEdit: boolean = false;
  dialogActionAdd: boolean = false;
  dialogActionDelete: boolean = false;

  //breadcurmb
  navItems: MenuItem[];

  //VALIDARE DIALOG !
  statusDialog: string;
  statusDialogMessage: Array<String>;



  constructor(private myMessage: MessageService, private dbs: DatabaseService, private myNavBar: NavigationService) {
    this.angajatiCols = [
      { field: 'nume_prenume', header: 'Nume Prenume' },
      { field: 'cnp', header: 'Cnp' },
      { field: 'localitate', header: 'Localitate' }
    ];
    this.myAngajat = new Angajat(0, "", "", "", 0);
    this.navItems = [
      { label: 'Home', icon: 'pi pi-fw pi-plus', routerLink: "/Home" },
      { label: 'Configuratie', icon: 'pi pi-fw pi-refresh', routerLink: "/Configuratie" },
      { label: 'Angjati', icon: 'pi pi-fw pi-refresh' }
    ];
  }

  ngOnInit() {
    this.myNavBar.sendNavbarItems(this.navItems);
    this.getPunctDeLucru();
  }




  onPtChange() {
    if (this.ptSelection) {
      this.getAngajatLista(this.ptSelection.id);
    }
  }


  angajatTableButtonClick(val: string) {

    if (!this.ptSelection) { return; }

    switch (val) {

      case "Add": {
        this.myAngajat = new Angajat(0, '', '', '', this.ptSelection.id);
        this.dialogActionAdd = true;
        break;
      }

      case "Edit": {
        if (!this.angajatiSelection) { return; }
        this.myAngajat = new Angajat(this.angajatiSelection.id, this.angajatiSelection.nume_prenume, this.angajatiSelection.cnp, this.angajatiSelection.localitate, this.ptSelection.id);
        this.dialogActionEdit = true;
        break;
      }

      case "Delete": {
        if (!this.angajatiSelection) { return; }
        this.myAngajat = new Angajat(this.angajatiSelection.id, this.angajatiSelection.nume_prenume, this.angajatiSelection.cnp, this.angajatiSelection.localitate, this.ptSelection.id);
        this.dialogActionDelete = true;
        break;
      }

    }
  }


  onAdaugaClick(angajat: Angajat) {
    this.dbs.ApiAngajatiAdd(angajat).subscribe(data => {
      this.statusDialog = 'dialogSuccess';
      this.statusDialogMessage = [data.Message];
      this.getAngajatLista(this.ptSelection.id);
    }, error => {
      this.statusDialog = 'dialogServerError';
      this.statusDialogMessage = [error.error.Message];
    });
  }


  onModificaClick(angajat: Angajat) {
    this.dbs.ApiAngajatiEdit(angajat).subscribe(data => {
      this.getAngajatLista(this.ptSelection.id);
      this.statusDialog = 'dialogSuccess';
      this.statusDialogMessage = [data.Message];
    }, error => {
      this.statusDialog = 'dialogServerError';
      this.statusDialogMessage = [error.error.Message];
    });
  }

  onStergeClick(angajat: Angajat) {
    this.dbs.ApiAngajatiDelete(angajat).subscribe(data => {
      this.getAngajatLista(this.ptSelection.id);
      this.statusDialog = 'dialogSuccess';
      this.statusDialogMessage = [data.Message];
    }, error => {
      this.statusDialog = 'dialogServerError';
      this.statusDialogMessage = [error.error.Message];

    });

  }

  //================================= API LIST ========================================================

  getAngajatLista(idPlucru: number) {
    this.angajatiDataSource = [];
    this.load = true;
    this.dbs.ApiAngajatiList(idPlucru)
      .subscribe(data => {
        this.angajatiDataSource = data;
        this.load = false;
      }, error => {
        this.statusDialog = 'dialogServerUnexcept';
        this.statusDialogMessage = ["Error CODE:  " + error.status, "Erorr Message:  " + error.error.Message];
      });
  }


  getPunctDeLucru() {
    this.pt_load = true;
    this.dbs.ApiPunctdelucruList()
      .subscribe(data => {
        this.pt_load = false;
        this.ptDataSource = data;
      }, error => {
        this.statusDialog = 'dialogServerUnexcept';
        this.statusDialogMessage = ["Error CODE:  " + error.status, "Erorr Message:  " + error.error.Message];
      });

  }




}

