import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(private httpclient: HttpClient) {

  }

  login(username: string, password: string): Observable<any> {
    var status = JSON.stringify({ "username": username, "password": password });
    console.log("Running Service Login :" + status);
    return this.httpclient.post('http://millennium.vps24.ro/api/Login.php', status);
  }

  verifyToken():Observable<any>{
    let token = sessionStorage.getItem("Token");
    console.log('verify service');
    return this.httpclient.get('http://millennium.vps24.ro/api/Token.php?token=' + token);
   }



}
