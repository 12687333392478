 
export class Angajat {
    id: number;
    nume_prenume: string;
    localitate: string;
    cnp: string;
    pt:number;
    constructor(id: number, nume_prenume: string, cnp: string, localitate: string,pt:number) {
      this.id = id;
      this.nume_prenume = nume_prenume;
      this.localitate = localitate;
      this.cnp = cnp;
      this.pt= pt;
    }
  
  }
  