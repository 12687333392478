export class Pauza {
  id: number;
  denumire: string;
  start: string;
  stop: string;
  night: number;
  timp_pauza:number;
  id_orar:number;
  
  constructor(id: number, denumire: string, start: string, stop: string, night: number,id_orar:number) {
    this.id = id;
    this.denumire = denumire;
    this.start = start;
    this.stop = stop;
    this.night = night;
    this.id_orar = id_orar;
  }
}
