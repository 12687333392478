import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(private httpclient: HttpClient) { }





 //============================== API CALCULATOR ORE ===================
 ApiCalculatorOrePut(CalculatorOre: any): Observable<any> {
  const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
  return this.httpclient.put<any>('http://millennium.vps24.ro/api/CalculatorOre.php', CalculatorOre, { headers: headers });

}
 









 //=============================Punct de Lucru =========================


  ApiPunctdelucruList(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.get<any>("http://millennium.vps24.ro/api/Punctdelucru.php", { headers: headers });
  }

  ApiPunctdelucruAdd(Punctdelucru: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.post<any>('http://millennium.vps24.ro/api/Punctdelucru.php', Punctdelucru, { headers: headers });
  }
  
  ApiPunctdelucruEdit(Punctdelucru: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.put<any>('http://millennium.vps24.ro/api/Punctdelucru.php', Punctdelucru, { headers: headers });
  
  }
  
  ApiPunctdelucruDelete(Punctdelucru: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.delete<any>('http://millennium.vps24.ro/api/Punctdelucru.php?id=' + Punctdelucru.id, { headers: headers });
  }


  //=============================ANGAJATI=========================

  ApiAngajatiList(pt: number): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.get<any>("http://millennium.vps24.ro/api/Angajat.php?punctdelucru=" + pt, { headers: headers });
  }

  ApiAngajatiAdd(Angajat: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.post<any>('http://millennium.vps24.ro/api/Angajat.php', Angajat, { headers: headers });
  }
  
  ApiAngajatiEdit(Angajat: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.put<any>('http://millennium.vps24.ro/api/Angajat.php', Angajat, { headers: headers });
  
  }
  
  ApiAngajatiDelete(Angajat: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.delete<any>('http://millennium.vps24.ro/api/Angajat.php?id=' + Angajat.id, { headers: headers });
  }
  
  




  //====================================== Orar ===============================
  ApiOrarList(plucru:number): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.get<any>("http://millennium.vps24.ro/api/Orar.php?id_plucru="+plucru, { headers: headers });
  }
  
  ApiOrarAdd(orar: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.post<any>('http://millennium.vps24.ro/api/Orar.php', orar, { headers: headers });
  }
  
  ApiOrarEdit(orar: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.put<any>('http://millennium.vps24.ro/api/Orar.php', orar, { headers: headers });
  
  }
  
  ApiOrarDelete(orar: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.delete<any>('http://millennium.vps24.ro/api/Orar.php?id=' + orar.id, { headers: headers });
  }
  

  //====================================== Pauza ===============================
  ApiPauzaList(id_orar:number): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.get<any>("http://millennium.vps24.ro/api/Pauza.php?id_orar="+id_orar, { headers: headers });
  }
  
  ApiPauzaAdd(pauza: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.post<any>('http://millennium.vps24.ro/api/Pauza.php', pauza, { headers: headers });
  }
  
  ApiPauzaEdit(pauza: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.put<any>('http://millennium.vps24.ro/api/Pauza.php', pauza, { headers: headers });
  
  }
  
  ApiPauzaDelete(pauza: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.delete<any>('http://millennium.vps24.ro/api/Pauza.php?id=' + pauza.id, { headers: headers });
  }
  





  //====================================== Faza ===============================
ApiFazaList(id_articol:number): Observable<any> {
  const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
  return this.httpclient.get<any>("http://millennium.vps24.ro/api/Faza.php?id_articol="+id_articol, { headers: headers });
}

ApiFazaAdd(faza: any): Observable<any> {
  const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
  return this.httpclient.post<any>('http://millennium.vps24.ro/api/Faza.php', faza, { headers: headers });
}

ApiFazaEdit(faza: any): Observable<any> {
  const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
  return this.httpclient.put<any>('http://millennium.vps24.ro/Faza.php', faza, { headers: headers });

}

ApiFazaDelete(faza: any): Observable<any> {
  const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
  return this.httpclient.delete<any>('http://millennium.vps24.ro/api/Faza.php?id=' + faza.id, { headers: headers });
}





//====================================== ARTICOL ===============================
ApiArticolList(): Observable<any> {
  const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
  return this.httpclient.get<any>("http://millennium.vps24.ro/api/Articol.php", { headers: headers });
}

ApiArticolAdd(articol: any): Observable<any> {
  const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
  return this.httpclient.post<any>('http://millennium.vps24.ro/api/Articol.php', articol, { headers: headers });
}

ApiArticolEdit(articol: any): Observable<any> {
  const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
  return this.httpclient.put<any>('http://millennium.vps24.ro/Articol.php', articol, { headers: headers });

}

ApiArticolDelete(articol: any): Observable<any> {
  const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
  return this.httpclient.delete<any>('http://millennium.vps24.ro/api/Articol.php?id=' + articol.id, { headers: headers });
}




  //============================ STATUS ==============================================

  ApiStatusList(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.get<any>("http://millennium.vps24.ro/api/Status.php", { headers: headers });
  }

  ApiStatusAdd(status: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.post<any>('http://millennium.vps24.ro/api/Status.php', status, { headers: headers });
  }

  ApiStatusEdit(status: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.put<any>('http://millennium.vps24.ro/api/Status.php', status, { headers: headers });

  }

  ApiStatusDelete(status: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'levike tokenje');
    return this.httpclient.delete<any>('http://millennium.vps24.ro/api/Status.php?id=' + status.id, { headers: headers });
  }

  //========================================================== END =============================================


  getOrarListByPunctDeLucru(id_punct_de_lucru: number) {

  }

}
