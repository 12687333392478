import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _navBarSource = new Subject<any>();
  navBar$=this._navBarSource.asObservable();
  constructor() { }

  sendNavbarItems(message:any){
    this._navBarSource.next(message);
  }
}
