import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/Service/Database/databaseserv.service';

class PunctdeLucru {
  id: number;
  denumire: string;
  locatie: string;
  constructor(id: number, denumire: string, locatie: string) {
    this.id = id;
    this.denumire = denumire;
    this.locatie = locatie;
  }

}


@Component({
  selector: 'app-punct-de-lucru-lista',
  templateUrl: './punct-de-lucru-lista.component.html',
  styleUrls: ['./punct-de-lucru-lista.component.css']
})




export class PunctDeLucruListaComponent implements OnInit {

  ptDataSource: any;
  ptSelection: any = null;
  ptCols: any;

  loadPt: boolean = false;

  //Class
  myPunctdeLucru: PunctdeLucru;

  //Dialog
  dialogActionEdit: boolean = false;
  dialogActionAdd: boolean = false;
  dialogActionDelete: boolean = false;

  //VALIDARE DIALOG !
  statusDialog: string;
  statusDialogMessage: Array<String>;


  //Accesare pagina Drepturi
  pageAccess: boolean = false;


  constructor(private dbs: DatabaseService) {
    this.ptCols = [
      { field: 'denumire', header: 'denumire' },
      { field: 'locatie', header: 'locatie' }
    ];
    this.myPunctdeLucru = new PunctdeLucru(0, '', '');
  }

  ngOnInit() {
    this.getPunctDeLucru();
    this.pageAccess = true;

  }


  ptTableButton(val: string) {
    switch (val) {

      case "Add": {
        this.myPunctdeLucru = new PunctdeLucru(0, '', '');
        this.dialogActionAdd = true;
        break;
      }

      case "Edit": {
        if (!this.ptSelection) { return; }
        this.myPunctdeLucru = new PunctdeLucru(this.ptSelection.id, this.ptSelection.denumire, this.ptSelection.locatie);
        this.dialogActionEdit = true;
        break;
      }

      case "Delete": {
        if (!this.ptSelection) { return; }
        this.myPunctdeLucru = new PunctdeLucru(this.ptSelection.id, this.ptSelection.denumire, this.ptSelection.locatie);
        this.dialogActionDelete = true;
        break;
      }

    }
  }




  onAdaugaClick(myPunctdeLucru: PunctdeLucru) {
    this.addPunctDeLucru(myPunctdeLucru);

  }


  onEditClick(myPunctdeLucru: PunctdeLucru) {
    this.editPunctDeLucru(myPunctdeLucru);
  }


  onDeleteClick(myPunctdeLucru: PunctdeLucru) {
    this.deletePunctDeLucru(myPunctdeLucru);
  }





  //==================================== API=========================================================

  getPunctDeLucru() {
    this.loadPt = true;
    this.dbs.ApiPunctdelucruList()
      .subscribe(data => {
        this.loadPt = false;
        this.ptDataSource = data;
      }, error => {
        this.statusDialog = 'dialogServerUnexcept';
        this.statusDialogMessage = ["Error CODE:  " + error.status, "Erorr Message:  " + error.error.Message];
      });

  }

  deletePunctDeLucru(myPunctdeLucru: PunctdeLucru) {
    this.dbs.ApiPunctdelucruDelete(myPunctdeLucru).subscribe(
      data => {
        this.statusDialog = 'dialogSuccess';
        this.statusDialogMessage = [data.Message];
        this.getPunctDeLucru();
      },
      error => {
        this.statusDialog = 'dialogServerError';
        this.statusDialogMessage = [error.error.Message];
      }
    );
  }

  editPunctDeLucru(myPunctdeLucru: PunctdeLucru) {
    this.dbs.ApiPunctdelucruEdit(myPunctdeLucru).subscribe(
      data => {
        this.statusDialog = 'dialogSuccess';
        this.statusDialogMessage = [data.Message];
        this.getPunctDeLucru();
      },
      error => {
        this.statusDialog = 'dialogServerError';
        this.statusDialogMessage = [error.error.Message];
      }
    );
  }

  addPunctDeLucru(myPunctdeLucru: PunctdeLucru) {
    this.dbs.ApiPunctdelucruAdd(myPunctdeLucru).subscribe(
      data => {
        this.statusDialog = 'dialogSuccess';
        this.statusDialogMessage = [data.Message];
        this.getPunctDeLucru();
      },
      error => {
        this.statusDialog = 'dialogServerError';
        this.statusDialogMessage = [error.error.Message];
      }
    );
  }







}
