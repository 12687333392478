export class CalculatorOre {
    id_plucru:number;
    id_angajat:number;
    id_orar:number;
    id_articol:number;
    id_faza:number;

    night:boolean;
    start:string;
    stop:string;

    constructor(id_plucru:number,id_angajat:number,id_orar:number,id_articol:number,id_faza:number,night:boolean,start:string,stop:string){
      this.id_plucru=id_plucru;
      this.id_angajat=id_angajat;
      this.id_orar=id_orar;
      this.id_articol=id_articol;
      this.id_faza=id_faza;
      this.night=night;
      this.start=start;
      this.stop=stop;
        

    }
}
