import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-raport-productie-page',
  templateUrl: './raport-productie-page.component.html',
  styleUrls: ['./raport-productie-page.component.css']
})
export class RaportProductiePageComponent implements OnInit {

  
  scrollableCols: any;
  cars: any;
  cols:any;

  constructor() {

    this.scrollableCols = [
      { field: 'crt', header: 'Crt.',width: '50px' },
      { field: 'date', header: 'Data',width: '100px' },
      { field: 'operator', header: 'Operator',width: '100px' },
      { field: 'status', header: 'Status',width: '100px' },
      { field: 'orar', header: 'Orar', width: '200px' },
      { field: 'start', header: 'Start', width: '50px' },
      { field: 'stop', header: 'Stop', width: '50px' },
      { field: 'aDenumire', header: 'Articol Denumire', width: '400px' },
      { field: 'aCod', header: 'Articol Cod', width: '200px' },
      { field: 'fDenumire', header: 'Faza Denumire', width: '400px' },
      { field: 'fCod', header: 'Faza Cod', width: '200px' },
      { field: 'prod', header: 'Prod (Buc/Sec)', width: '200px' },
      { field: 'bucatiProduse', header: 'Bucati Produse', width: '200px' },
      { field: 'normaBucati', header: 'Norma Bucati', width: '200px' },
      { field: 'diffBucati', header: 'Diferanta Bucati', width: '200px' },
    ];

   
  }

  ngOnInit() {
    this.cars = [
      { crt:"", date: '01-01-2017', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'okhhhhhhhhhhhhhhhhh hhhhhhhhhhhhhhh ggggggggg', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: '01-01-2017', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'okhhhhhhhhhhhhhhhhh hhhhhhhhhhhhhhh ggggggggg', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: '01-01-2017', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'okhhhhhhhhhhhhhhhhh hhhhhhhhhhhhhhh ggggggggg', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: '01-01-2017', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'okhhhhhhhhhhhhhhhhh hhhhhhhhhhhhhhh ggggggggg', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
      { date: 'ok', operator: '2017', status: 'ok', orar: 'white',start: 'ok', stop: '2017', aDenumire: 'ok', aCod: 'white',fDenumire: 'ok', fCod: 'white',prod: 'ok', bucatiProduse: '2017', normaBucati: 'ok', diffBucati: 'white' },
    ];


  }


  
}
